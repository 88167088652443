import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import { Button } from "reactstrap"
import { useAuth } from "@auth/useAuth"
import { navigate } from "gatsby"
import { format } from "date-fns"
import themeConfig from "../config/themeConfig"
import "./login.less"

const getLogo = () => {
  if (typeof window === "undefined") {
    return require(`@images/logos/logo.svg`)
  }
  try {
    return require(`@images/logos/${window.location.hostname}.svg`)
  } catch (err) {
    return require(`@images/logos/logo.svg`)
  }
}

const loginImage = () => {
  if (typeof window === "undefined") {
    return require(`@images/login-image.jpg`)
  }
  try {
    return require(`@images/login-images/${window.location.hostname}.jpg`)
  } catch (err) {
    return require(`@images/login-image.jpg`)
  }
}

const isWorksimply = () =>
  typeof window !== "undefined" &&
  window.location.hostname.includes("worksimply")

const LoginPage = (props) => {
  const { clientName } = themeConfig()
  const { authorize, isAuthenticated } = useAuth() || {}

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated])

  const [visible, setVisible] = useState("")

  return (
    <>
      <SEO title={`Welcome to ${clientName}`} />
      <div className="login-page">
        <div className="left">
          <img src={getLogo().default} alt="Logo" />
          <div className="login-body">
            <h1>
              Welcome to the {clientName} <br />
              Listing management tool
            </h1>
            <Button
              color="primary"
              size="lg"
              className="btn-square"
              onClick={authorize}
            >
              Sign In
            </Button>
          </div>
          <p className="login-footer">
            {isWorksimply() && (
              <>
                &copy; {format(new Date(), "yyyy")} Worksimply Inc. All rights
                reserved.
              </>
            )}
          </p>
        </div>

        <div className="right">
          <img
            src={loginImage().default}
            alt="Welcome to Worksimply"
            className={visible}
            onLoad={() => setVisible("visible")}
          />
        </div>
      </div>
    </>
  )
}

export default LoginPage
